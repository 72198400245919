<template>
    <div v-for="(tab, index) in tabHeadings" :key="index" class="col-auto mx-2 py-2 user-select-none"
        :id="`${this.getDefaultId(index, 'page-label-' + index + '-' + this.tabComponentUUID)}`" :class="{
            'border-bottom-page': selectedTabIndex === index,
        }" style="cursor: pointer;" @click="selectTab(index)">
        <div class="d-flex align-items-center">
            <p v-if="tab.badgeText" v-text="tab.badgeText" class="badge badge-pill"
                style="background: var(--sah-error);z-index:5;padding:2px 5px;text-align:center;display:flex;justify-content: center;align-items:center;font-size:10px;margin-right:-5px;margin-top:-5px;">
            </p>
            <p :class="['pageLabel', selectedTabIndex === index ? 'activePage activePageLabel' : '']">
                {{ tab[tabTitleProperty] || getDefaultTitle(index) }}
            </p>
        </div>
    </div>
</template>

<script>
/**
 * Reusable Tabs Component (note, this is for the tabs, not the tab content).
 *
 * @prop {Array} tabHeadings - Array of values being iterated through.
 * @prop {number} selectedTabIndex - Index of the selected tab.
 * @prop {string} tabTitleProperty - Reference within the tabHeadings array to use as the tab title.
 * @prop {string} defaultTabTitle - Code template for tab titles if tabTitleProperty doesn't exist.
 *
 * @event changed - Emits the index of the selected tab.
 *
 * @example
 * <tabs-component
 *   :tabHeadings="form.pages"
 *   :selectedTabIndex="createParams.selectedPage"
 *   tabTitleProperty="name"
 *   defaultTabTitle="`Page ${index + 1}`"
 *   @changed="index => selectPage(index)"
 * />
 */

export default {
    name: 'tabs-component',
    props: {
        tabHeadings: Array,
        selectedTabIndex: Number,
        tabTitleProperty: String,
        defaultTabTitle: String,
        // optional id for the tab
        id: String,
    },
    emits: ['changed'],
    data() {
        return {
            tabComponentUUID: crypto.randomUUID(),
        }
    },
    methods: {
        /**
         * Select a tab by index and scroll it into view.
         *
         * @param {number} index - Index of the selected tab.
         *
         * @returns {void}
         */
        selectTab(index) {
            const id = this.getDefaultId(index, 'page-label-' + index + '-' + this.tabComponentUUID);
            const el = document.querySelector(`#${id}`);
            if (el) {
                // only scroll if tab compoent is scrollable
                // var hasHorizontalScrollbar = el.parentNode.scrollWidth >= el.parentNode.clientWidth;
                // console.log(el, hasHorizontalScrollbar, el.parentNode.scrollWidth, el.parentNode.clientWidth);
                // if(hasHorizontalScrollbar)
                el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
            this.$emit('changed', index);
        },

        /**
         * Get the default title for a tab based on the index.
         *
         * @param {number} index - Index of the tab.
         *
         * @returns {string} - Default tab title.
         */
        getDefaultTitle() {
            return eval(this.defaultTabTitle);
        },
        getDefaultId(index, defaultId) {
            if (this.tabHeadings[index].id && this.tabHeadings[index]?.specificId) {
                return this.tabHeadings[index].id;
            }

            if (this.id) {
                return eval(this.id);
            } else {
                return defaultId;
            }
        }
    },
};
</script>

<style scoped>
.border-bottom-page {
    border-bottom: 0.125rem solid #3672BA !important;
}
</style>
