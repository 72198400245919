<template>
  <div v-if="this.signed !== true && this.requestValidated"
    class="sm:hidden flex  top-0 relative bg-white  w-screen items-center px-16 gap-4 sm:flex-row flex-col justify-between py-4"
    style="z-index:15">
    <img class="h-8 sm:h-10" src="/RapidSign.png" aria-label="RapidSign.net" />
  </div>
  <div v-if="this.signed !== true && this.requestValidated"
    class="sm:hidden shadow visible sticky top-0 bg-white w-screen flex items-center px-16 gap-4 sm:flex-row flex-col justify-between py-4"
    style="z-index:10">
    <button @click="this.ScrollToSignArea()" id="sign-shortcut"
      class=" flex flex-row justify-center items-center btn btn-primary sm:w-auto w-full">Sign
      Document

    </button>
  </div>
  <div v-if="this.signed !== true && this.requestValidated"
    class="sm:flex hidden top-0 z-10 sticky bg-white shadow w-screen  items-center px-16 gap-4 sm:flex-row flex-col justify-between py-4">
    <img class="h-8 sm:h-10" src="/RapidSign.png" aria-label="RapidSign.net" />
    <button @click="this.ScrollToSignArea()" id="sign-shortcut"
      :class="`btn btn-primary sm:w-auto w-full ${this.requestValidated && this.secretKeyCollected && this.secretKey != '' ? 'opacity-100' : 'opacity-0'}`">Sign
      Document
    </button>
  </div>
  <div v-if="this.secretKeyCollected === false" class="h-screen flex  flex-col gap-3 justify-center mt-[-64px]">
    <label for="access-code" class="error" v-if="this.error" v-text="this.error"></label>
    <label for="access-code">Enter your secure access code:</label>
    <input id="access-code" @keyup.enter="secretKeyCollected = true" autofocus placeholder="1234"
      v-model="this.secretKey"
      class="form-control mx-auto mt-4 ring-0 focus:ring-0 focus:outline-none rounded-0 text-lg px-4 py-2" style="border: 1px solid var(--Light-Gray, #DDD);
" />
    <div class="mt-8">
      <button @click="secretKeyCollected = true" class="w-auto btn btn-primary">Access Signature Portal</button>
    </div>
  </div>

  <div v-else-if="this.signed !== true && this.requestValidated" class="mb-8 mt-[-64px] mt-sm-[-32px]">&nbsp;</div>
  <RemoteSign v-if="this.secretKeyCollected"
    @requestValidated="val => { this.secretKeyCollected = false; this.secretKey = ''; }"
    :requestValidated="this.requestValidated" :signed="this.signed" @signed="val => this.signed = val"
    :secretKey="this.secretKey" @error="error => this.error = error" />
</template>

<script>
import RemoteSign from './components/RemoteSign.vue'

export default {
  name: 'RemoteSignature',
  components: {
    RemoteSign,
  },
  data() {
    return {
      signed: false,
      requestValidated: true,
      secretKey: '',
      secretKeyCollected: false,
      error: '',
    }
  },
  methods: {
    ScrollToSignArea() {
      document.querySelector('a[name="start-sign"]').scrollIntoView({ behavior: 'smooth' });
    }
  },
}
</script>

<style>
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(250, 250, 250);
  --sah-confirmation: #51A849;
  --sah-confirmation-btn-text: #FFFFFF;
  --sah-blue: rgb(54, 114, 186);
  font-family: 'Inter';
  --sah-button-radius: 3px;
  --sah-destructive: #E8E9EB;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(81, 168, 73);
  --bs-btn-disabled-border-color: rgb(81, 168, 73);
  --sah-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px 0px;
}

.btn.btn-primary {
  background: var(--sah-confirmation) !important;
  padding: 10px 14px !important;
  border: 2px solid var(--sah-confirmation) !important;
  color: var(--sah-confirmation-btn-text) !important;
  font-weight: 600;
}

.btn.btn-link {
  background: transparent;
  color: var(--sah-blue) !important;
  padding: 10px 14px !important;
  border: 2px solid transparent !important;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.error {
  color: #EF000E;
  font-weight: 500;
}
</style>
