import { createApp } from 'vue'
import App from './App.vue'
import './index.css'

createApp(App).mount('#app')


/*
* Returns true if the element is fully visible within the container.
* @param {HTMLElement} element The element to check.
* @param {HTMLElement} container The container to check.
* @returns {boolean} True if the element is fully visible within the container; otherwise, false.
* Modified From: https://stackoverflow.com/questions/75002332/check-if-child-element-is-100-visible-inside-a-parent-div-that-has-overflow-hid
*/
export function isElementFullyVisible(element, container) {
    // Get the bounding rectangles of the element and the container.
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // Initialize the result to false (not fully visible).
    let result = false;

    // Check if the element's boundaries are completely within the container's boundaries.
    if (elementRect.x >= containerRect.x &&
        elementRect.y >= containerRect.y &&
        elementRect.x + elementRect.width <= containerRect.x + containerRect.width &&
        elementRect.y + elementRect.height <= containerRect.y + containerRect.height) {
        // Set the result to true if the element is fully visible within the container.
        result = true;
    }

    // Return the result.
    return result;
}