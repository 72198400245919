<template>
  <div style="">
    <div class="guidance-box" ref="box"
      :class="`guidance-box--${level} flex flex-col items-start gap-[10px] bg-white py-1`"
      :style="'width:fit-content; max-width:50%'">
      <div :class="`d-flex flex-row items-start justify-content-start w-100 ${this.mlOffsetClass}`"
        style=" padding-top: 15px; gap:20px">
        <img v-if="level == 'info'" src="../assets/info-i.svg" class="user-select-none guidance-box__icon"
          alt="Notification icon" height="30" />
        <div class="guidance-box__content">
          <h5 style="font-weight: semi-bold">{{ heading }}</h5>
          <div class="max-w-50" style="font-weight: 400">
            <slot></slot>
          </div>
          <div v-if="calloutText" style="font-size: 30px;" class="font-monospace my-3">
            {{ calloutText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuidanceBox',
  emits: ['close'],
  computed: {
    widthProp() {
      if (this.width == 'fit') {
        return 'fit-content';
      }
      return this.width;
    }
  },
  props: {
    level: {
      type: String,
      default: 'info'
    },
    heading: {
      type: String,
      required: true
    },
    calloutText: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: false
    },
    width: {
      type: String,
      default: '98%'
    },
    closeable: {
      type: Boolean,
      default: true
    },
    highlight: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.updateBoxHeight();
    window.addEventListener('resize', this.updateBoxHeight);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateBoxHeight);
  },
  data() {
    return {
      isChecked: this.checked,
      boxHeight: 0,
      mlOffsetClass: 'ml-15px',
    };
  },
  methods: {
    updateBoxHeight() {
      this.boxHeight = this.$refs.box ? this.$refs.box.offsetHeight : 0;
    },
    handleAction() {
      if (this.action) {
        eval(this.action);
      }
    },
    CloseBox() {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.guidance-box {
  border-radius: var(--sah-card-radius);
  box-shadow: var(--sah-box-shadow);
  border: 1px solid #D7DADD;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
</style>

<style>
.ml-15px {
  margin-left: 15px;
}
</style>
