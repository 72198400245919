<template>
    <div class="scrollable-container">
        <button v-if="showScrollLeft" @click="scrollLeft" class="scroll-btn scroll-btn-left bg-none"
            v-html="this.ScrollLeftSVG"></button>
        <div class="scrollable-container" ref="container" @scroll="handleScroll" :class="{
        }">
            <div class="scrollable-content" ref="content">
                <slot></slot>
            </div>
        </div>
        <button v-if="showScrollRight" @click="scrollRight" class="scroll-btn scroll-btn-right bg-none"
            v-html="this.ScrollRightSVG"></button>
    </div>
</template>

<script>
// either 250 or 15% of the container width
const SCROLL_OFFSET = Math.min(250, 0.15 * window.innerWidth);

export default {
    // eslint-disable-next-line
    name: 'Scrollable',
    props: {
    },
    created() {
        this.checkScroll();
    },
    data() {
        return {
            showScrollLeft: false,
            showScrollRight: false,
            ScrollLeftSVG: `<svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9.5L1 5.5L5 1.5" stroke="#3C3C3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 9.5L1 5.5L5 1.5" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
            ScrollRightSVG: `<svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9.5L5 5.5L1 1.5" stroke="#3C3C3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 9.5L5 5.5L1 1.5" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`
        };
    },
    methods: {
        checkScroll() {
            const container = this.$refs.container;
            if (container) {
                if (isNaN(container.scrollLeft)) {
                    container.scrollLeft = 0;
                }
                this.showScrollLeft = container.scrollLeft > 0;
                this.showScrollRight = container.scrollLeft < (container.scrollWidth - container.clientWidth) - 15;
            }
        },
        scrollLeft() {
            const container = this.$refs.container;
            if (container) {
                if (isNaN(container.scrollLeft)) {
                    container.scrollLeft = 0;
                } else {
                    container.scrollLeft -= SCROLL_OFFSET;
                }
                this.checkScroll();
            }
        },
        scrollRight() {
            const container = this.$refs.container;

            if (container) {
                if (isNaN(container.scrollLeft)) {
                    container.scrollLeft = SCROLL_OFFSET;
                } else {
                    container.scrollLeft += SCROLL_OFFSET;
                }
                this.checkScroll();
            }
        },
        handleScroll() {
            this.checkScroll();
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.checkScroll();

            // Observe container resize
            const content = this.$refs.content;
            const resizeObserver = new ResizeObserver(this.checkScroll);
            resizeObserver.observe(content);
        });
    },
    watch: {
        '$refs.container.scrollWidth': 'checkScroll',
        '$refs.container.clientWidth': 'checkScroll'
    }
};
</script>

<style scoped>
.scrollable-container {
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    display: flex;
    flex-direction: row;
    width: 100%;


    border-bottom: 1px solid #e2e8f0;
    display: flex !important;
    flex-direction: row !important;
    width: 100%;
}

.scrollable-container::-webkit-scrollbar {
    display: none;
}

.scrollable-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
}

.scroll-btn {
    z-index: 1;
    transform: translateY(-25%);
    border: none !important;
}

.scroll-btn-left {
    left: 0px;
}

.scroll-btn-right {
    right: 0px;
}

button {
    background: none;
    border-radius: var(--sah-button-radius);
}
</style>
